import {t} from "i18next";

export const translateRecharge = str => {
  switch (str) {
    case "Delivery every 1 month":
      return t("subscription.delivery_1_month");
    case "Delivery every 2 months":
      return t("subscription.delivery_2_months");
    case "Delivery every 3 months":
      return t("subscription.delivery_3_months");
    case "Delivery every 4 months":
      return t("subscription.delivery_4_months");
    case "Delivery every 5 months":
      return t("subscription.delivery_5_months");
    case "Delivery every 6 months":
      return t("subscription.delivery_6_months");
    case "Delivery every 7 months":
      return t("subscription.delivery_7_months");
    case "Delivery every 8 months":
      return t("subscription.delivery_8_months");
    case "1-month":
      return t("subscription.add_to_cart.month_1");
    case "2-month":
      return t("subscription.add_to_cart.month_2");
    case "3-month":
      return t("subscription.add_to_cart.month_3");
    case "4-month":
      return t("subscription.add_to_cart.month_4");
    case "5-month":
      return t("subscription.add_to_cart.month_5");
    case "6-month":
      return t("subscription.add_to_cart.month_6");
    case "7-month":
      return t("subscription.add_to_cart.month_7");
    case "8-month":
      return t("subscription.add_to_cart.month_8");
    default:
      return str;
  }
};
